import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableBody } from '@mui/material'
import { TableRow } from '@mui/material'
import { TablePagination } from '@mui/material'
import { TableContainer, Paper } from '@mui/material'
import moment from 'moment'
import React from 'react'
import AdminControllButtons from '../../UI/Admin/AdminControllButtons/AdminControllButtons'
import EmptyData from '../../UI/Admin/EmpyData/EmptyData'
import TableTh from '../../UI/Table/TableTh/TableTh'
import classes from '../AdminView.module.css'

const AdminEvents = (props) => {
    const {
        events,
        pageSize,
        pageNumber,
        total,
        handleChangePage,
        handlePageSize,
        handleRemove,
        handleEdit,
        searchValue,
        getEvents
    } = props

    const rows = [
        {
            key: 'poster',
            text: "Poster",
            searchByValue: ""
        },
        {
            key: 'title',
            text: "Title",
            searchByValue: "title"
        },
        {
            key: 'average',
            text: "Average Ticket Price",
            searchByValue: ''
        },
        {
            key: 'count',
            text: "Tickets Count",
            searchByValue: ''
        },
        {
            key: 'date',
            text: "Date",
            searchByValue: "date"
        },
        {
            key: '',
            text: "",
            searchByValue: ""
        }
    ]

    return (
        <div className={classes.main}>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {rows.map(item => (
                                <TableTh
                                    key={item.key}
                                    text={item.text}
                                    onSort={getEvents}
                                    searchByValue={item.searchByValue}
                                    searchValue={searchValue}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.map(item => (
                            <TableRow key={item._id}>
                                <TableCell width={"100px"}>
                                    <img src={item.poster.image} alt="poster" className={classes.imgPreview}/>
                                </TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.averagePrice} uah</TableCell>
                                <TableCell>{item.ticketsCount} | {item.freeTicketsCount + item.ticketsCount}</TableCell>
                                <TableCell width={"25%"}>{moment(item.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell width={120}>
                                    <AdminControllButtons
                                        item={item}
                                        onRemove={handleRemove}
                                        onEdit={handleEdit}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {events.length === 0 && <EmptyData/>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component={"div"}
                rowsPerPage={pageSize}
                page={pageNumber}
                count={total}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handlePageSize}
                className={classes.pagination}
            />
        </div>
    )
}

export default AdminEvents